/** @jsx jsx */
import { Flex, Text, Container, jsx } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { JoinNavButton } from '@components/Header'
import { useMediaQuery } from 'react-responsive'

export const BuildCommunity = () => {
  let coverImageStyle = {
    bottom: '100px',
    left: '300px',
    height: '533px',
  }

  const isMobile = useMediaQuery({ query: '(max-width: 1300px)' })
  const isSmallMobile = useMediaQuery({ query: '(max-width: 900px)' })

  if (isMobile) {
    coverImageStyle = { marginTop: '20px', alignSelf: 'center' }
  }

  if (isSmallMobile) {
    coverImageStyle = { marginTop: '20px', alignSelf: 'center', width: '95vw' }
  }

  const data = useStaticQuery(
    graphql`
      query buildCommunityImage {
        file(relativePath: { eq: "build_community/community.jpg" }) {
          childImageSharp {
            fixed(height: 533, width: 800) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        height: ['67%'],
        mt: '20px',
        p: [0, 5],
        py: [5],
        mx: [null, null, null, 4, '200px'],
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          bg: 'lightBlue',
          p: [5],
          width: ['100%', null, null, null, '50vw'],
        }}
      >
        <Text
          variant="h2"
          sx={{
            color: 'black',
            fontFamily: 'Futura Heavy',
            fontSize: ['40px', '44px'],
          }}
        >
          How we build community
        </Text>
        <Text
          sx={{
            fontFamily: 'Futura Medium',
            fontSize: [3],
            color: 'black',
            mt: [2],
          }}
        >
          In creating a community through in-person events and virtual networks,
          MiH-RCN thrives because of the diversity of its members. To accomplish
          our goal, we need traditional knowledge holders, natural scientists,
          engineers, student economists, social scientists, health
          professionals, cultural heritage workers, educators, and you.
        </Text>
        <Flex mt={[3]}>
          <JoinNavButton />
        </Flex>
      </Flex>
      <Img fixed={data.file.childImageSharp.fixed} style={coverImageStyle} />
    </Flex>
  )
}

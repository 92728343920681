import React from 'react'
import { Text, Flex } from 'theme-ui'

import { useStaticQuery, graphql, Link } from 'gatsby'
import { EventCard } from '@components/Events/EventTabs'
import LongArrowRight from '../../../assets/long-arrow-right.svg'
import { ContentfulEvent } from 'graphqlTypes'

export const WhereWeGather = () => {
  const data = useStaticQuery(
    graphql`
      query homeFeaturedEvents {
        featuredEvents: contentfulHomePage(
          id: { eq: "2a8bc773-a4cf-5f33-98e6-cbd65afef32f" }
        ) {
          whereWeGatherEvents {
            date
            eventType
            title
            rsvpLink
            locationText
            description
            eventImage {
              fixed(width: 500, height: 300) {
                ...GatsbyContentfulFixed
              }
            }
          }
        }
      }
    `
  )

  const featuredEvents = data.featuredEvents.whereWeGatherEvents

  // sort events in latest to most recent
  featuredEvents.sort((a, b) => {
    const dateA = new Date(a.date)
    const dateB = new Date(b.date)
    return dateA - dateB
  })

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        height: ['67%', null, null, null, '100%'],
        px: [3, 5],
        py: [5],
        mx: [0, null, null, 4, 6],
        bg: 'lightBlue',
      }}
    >
      <Flex
        sx={{
          justifyContent: ['center', 'flex-start'],
        }}
      >
        <Text
          as="h2"
          sx={{
            textAlign: ['center', 'left'],
            fontFamily: 'Futura Heavy',
            fontSize: ['40px', '44px'],
            mb: ['10px', 0],
          }}
        >
          Where we gather
        </Text>
      </Flex>
      <Flex>
        <Text
          as="h3"
          sx={{
            fontFamily: 'Futura Medium',
            fontSize: ['18px'],
            maxWidth: [null, null, null, '66vw', '50vw'],
            textAlign: ['center', 'left'],
          }}
        >
          Researchers are stronger when we work together. That's why each year,
          MiH-RCN hosts webinars, workshops, conference panels, and one major
          network meeting. Explore our events to meet other researchers.
        </Text>
      </Flex>
      <Flex
        sx={{
          mt: [5],
          justifyContent: ['center', 'space-between'],
          flexWrap: 'wrap',
        }}
      >
        {featuredEvents.map((event: ContentfulEvent) => (
          <Flex
            sx={{
              mt: [4, 3, 2, 0],
            }}
          >
            <EventCard key={event.id} event={event} />
          </Flex>
        ))}
      </Flex>
      <Flex
        sx={{
          justifyContent: ['center', 'flex-start'],
          mt: ['20px'],
        }}
      >
        <Link to="/events" style={{ textDecoration: 'none' }}>
          <Flex>
            <Text
              sx={{
                fontFamily: 'Futura Heavy',
                fontSize: '20px',
                color: 'primary.one',
                textDecoration: 'none',
                mr: ['10px'],
                textAlign: ['center', 'left'],
              }}
            >
              View all events
            </Text>
            <LongArrowRight
              style={{
                marginTop: '2px',
              }}
              width={32}
              height={24}
              fill={'#147CED'}
            />
          </Flex>
        </Link>
      </Flex>
    </Flex>
  )
}

export const WhatWeDoNodes = [
  {
    name: 'Communication',
    icon: 'speech',
    description: `We facilitate open communication and a safe space to share information, resources, and ideas across siloed disciplines of Arctic migration research`,
  },
  {
    name: 'Science',
    icon: 'science',
    description: `We integrate current research and initiatives of researchers, educators, and engineers working independently on migration topics related to Arctic coastal areas`,
  },
  {
    name: 'Society',
    icon: 'team',
    description: `We improve decision-support for Arctic coastal residents, government agencies, and non-governmental organizations in responding to the consequences of changing migration patterns`,
  },
  {
    name: 'Education',
    icon: 'think',
    description: `We nurture a sense of community within the network by enhancing education, training, and exchange opportunities for all members, from high schoolers to senior scholars`,
  },
]

import React from 'react'

import { Text, Flex, Box, Container } from 'theme-ui'

import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import LongArrowRight from '../../../assets/long-arrow-right.svg'
import { useMediaQuery } from 'react-responsive'

export const WhatWeCreate = () => {
  let coverImageStyle = { width: '700px', height: '550px', marginTop: '50px' }

  const isSmallMobile = useMediaQuery({ query: '(max-width: 550px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 1050px)' })
  const isLargeScreen = useMediaQuery({ query: '(min-width: 2000px)' })

  if (isMobile) {
    coverImageStyle = { width: '500px', height: '400px', marginTop: '50px' }
  }

  if (isLargeScreen) {
    coverImageStyle = { width: '900px', height: '700px', marginTop: '50px' }
  }

  if (isSmallMobile) {
    coverImageStyle = { width: '500px', height: '300px', marginTop: '50px' }
  }

  const data = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "what_we_create/cover.jpg" }) {
          childImageSharp {
            fixed(height: 1047, width: 1231) {
              originalName
              ...GatsbyImageSharpFixed
            }
          }
        }
        backgroundImage: file(
          relativePath: { eq: "what_we_create/background.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        height: ['67%'],
        p: [5],
        mb: '50px',
        mx: [0, null, null, 4, 6],
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
        }}
      >
        <Text
          as="h2"
          sx={{
            fontFamily: 'Futura Heavy',
            fontSize: ['44px'],
          }}
        >
          What we create
        </Text>
        <Text
          sx={{
            fontFamily: 'Futura medium',
            fontSize: ['18px'],
            width: ['100%', '50vw'],
          }}
        >
          Knowledge is power only if it is made accessible and used towards
          cooperative solutions. MiH-RCN produces meeting reports, special
          journal issues, infographics, videos, and education materials on
          various topics related to the Arctic.
        </Text>
      </Flex>
      <Flex
        sx={{
          flexDirection: 'row',
          mt: '20px',
          height: ['480px', '480px', '480px', '580px'],
          '@media screen and (min-width: 2000px)': {
            height: '750px',
          },
        }}
      >
        <Img fixed={data.image.childImageSharp.fixed} style={coverImageStyle} />
        <Box
          sx={{
            backgroundColor: 'lightBlue',
            width: ['50vw', null, null, '33vw', '33vw'],
            height: ['500px', null, null, '600px', '680px'],
            position: 'absolute',
            marginLeft: '33vw',
            zIndex: -1,
            overflow: '',
          }}
        />
      </Flex>

      <Flex mt="50px">
        <Link to="/resources" style={{ textDecoration: 'none' }}>
          <Flex>
            <Text
              sx={{
                fontFamily: 'Futura Heavy',
                fontSize: '20px',
                color: 'primary.one',
                textDecoration: 'none',
                letterSpacing: '0.14px',
                mr: ['10px'],
              }}
            >
              View our work
            </Text>
            <LongArrowRight
              style={{
                marginTop: '2px',
              }}
              width={32}
              height={24}
              fill={'#147CED'}
            />
          </Flex>
        </Link>
      </Flex>
    </Flex>
  )
}

import React from 'react'

import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'

import {
  Hero,
  WhatWeDo,
  WhatWeCreate,
  WhereWeGather,
  BuildCommunity,
} from '@components/Home'

const IndexPage = () => (
  <Layout page="home">
    <SEO />
    <Hero />
    <WhatWeDo />
    <WhatWeCreate />
    <WhereWeGather />
    <BuildCommunity />
  </Layout>
)

export default IndexPage

import React from 'react'
import { Box, Grid, Text } from 'theme-ui'
import Header from '@components/Header'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export const Hero = () => {
  return (
    <Box
      bg={['primary.two', 'primary.two']}
      sx={{
        minHeight: ['100%', null, null, '100%', '100vmin'],
        backgroundImage: [
          null,
          null,
          `
            linear-gradient(
              to right,
              #007ae8 0%,
              #007ae8 35%,
              white 35%,
              white 100%
            );
          `,
        ],
      }}
    >
      <Header page="home" />
      <HeroCalloutOne />
      <HeroImage />
      <HeroCalloutTwo />
    </Box>
  )
}

const HeroCalloutOne = () => {
  return (
    <Grid columns={['1fr', null, '1fr 2fr']}>
      <Text
        as="h2"
        sx={{
          fontFamily: 'Futura Heavy',
          fontSize: [null, null, '28px', '30px', '52px'],
          letterSpacing: 0,
          fontWeight: 'bold',
          color: '#FFFFFF',
          mb: 3,
          textAlign: ['center', null, 'right'],
        }}
      >
        From{' '}
        <Box
          sx={{
            display: 'block',
          }}
        />
        demographics to diseases{' '}
        <Box
          sx={{
            display: 'block',
          }}
        />
        economies to ecosystems{' '}
        <Box
          sx={{
            display: 'block',
          }}
        />
        ships to species
      </Text>
      <Text
        as="h1"
        sx={{
          letterSpacing: 0,
          opacity: 1,
          fontFamily: 'Futura Extra Black',
          textAlign: 'center',
          fontSize: ['44px', null, '50px', '60px', '88px'],
          fontWeight: 'Bold',
          color: ['white', 'white', 'black', 'black', 'black'],
          mx: ['5vw', '5vw', '5vw', '5vw', 'auto'],
          my: 4,
        }}
      >
        THE ARCTIC IS{' '}
        <Box
          sx={{
            display: 'block',
          }}
        />
        ON THE MOVE
      </Text>
    </Grid>
  )
}

const HeroCalloutTwo = () => {
  return (
    <Grid columns={['1fr', null, '1fr 2fr']}>
      <Text
        as="h2"
        sx={{
          fontFamily: 'Futura Bold',
          fontSize: ['40px', null, '28px', '39px', '44px'],
          letterSpace: '0.22px',
          color: 'white',
          mt: 5,
          textAlign: ['center', null, 'right'],
        }}
      >
        Who we are
      </Text>
      <Text
        sx={{
          fontFamily: 'Futura Book Regular',
          color: ['white', null, 'grey'],
          fontSize: '20px',
          my: [2, 5],
          mb: [5, null],
          mx: ['25px', '100px'],
          textAlign: ['center', 'center', 'left'],
        }}
      >
        Migration in Harmony is a Research Coordination Network on Arctic
        migrations funded by the National Science Foundation. We are a network
        of researchers, practitioners, and traditional knowledge holders working
        to synthesize current research, jointly identify and prioritize research
        topics, and build collaborative project teams to support a resilient,
        just, and sustainable Arctic in motion.
      </Text>
    </Grid>
  )
}

const HeroImage = () => {
  const data = useStaticQuery(
    graphql`
      query homePage {
        contentfulHomePage(id: { eq: "2a8bc773-a4cf-5f33-98e6-cbd65afef32f" }) {
          heroImage {
            fluid(maxHeight: 430, maxWidth: 1700) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    `
  )

  return (
    <Box px={[null, null, null, '50px', 105]} mx={2}>
      <Img
        fluid={data.contentfulHomePage.heroImage.fluid}
        key={data.contentfulHomePage.heroImage.fluid.src}
        alt="Migration Harmony hero collage"
      />
    </Box>
  )
}

export default { Hero }

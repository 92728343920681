import React from 'react'

import { Grid, Text, Flex } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { WhatWeDoNodes } from '../../../util/config'

export const WhatWeDo = () => {
  const data = useStaticQuery(
    graphql`
      query {
        icons: allFile(filter: { relativeDirectory: { eq: "what_we_do" } }) {
          nodes {
            childImageSharp {
              fixed(height: 50, width: 50) {
                originalName
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    `
  )

  return (
    <Grid
      sx={{
        height: [null, '450px', null, '400px', '600px'],
        bg: '#F8F8F8',
        p: [3, null, null, 3, 5],
        gridTemplateColums: ['1fr', 'repeat(4, 1fr)'],
        gridTemplateRows: ['repeat(4, 1fr)', 'repeat(2, 1fr)'],
        gridTemplateAreas: [
          `
          'what-we-do'
          'item-1'
          'item-2'
          'item-3'
          'item-4'
        `,
          `
          'what-we-do what-we-do what-we-do what-we-do'
          'item-1 item-2 item-3 item-4'
        `,
        ],
      }}
    >
      <Grid>
        <Text
          as="h2"
          sx={{
            color: 'black',
            alignSelf: 'center',
            letterSpacing: '0.22px',
            fontFamily: 'Futura Bold',
            fontSize: ['36px', null, null, '40px', '44px'],
            fontWeight: 'bold',
            ml: ['60px'],
            minWidth: ['300px'],
          }}
        >
          What we do
        </Text>
      </Grid>
      {WhatWeDoNodes.map(({ name, icon, description }, index) => {
        const fixed = data.icons.nodes.filter(({ childImageSharp }) =>
          childImageSharp.fixed.originalName.includes(icon)
        )
        return (
          <Grid key={name} sx={{ gridArea: `item-${index + 1}` }}>
            <Flex
              sx={{
                maxWidth: '400px',
                alignItems: 'center',
                flexDirection: 'column',
                mb: '20px',
              }}
            >
              <Img
                fixed={fixed[0].childImageSharp.fixed}
                key={fixed[0].childImageSharp.fixed.src}
                style={{
                  width: '50px',
                  height: '50px',
                  marginBottom: '10px',
                }}
                alt={`what-we-do-${icon}`}
              />
              <Text
                as="h3"
                sx={{
                  mb: '10px',
                  fontFamily: 'Futura Bold',
                  fontSize: '24px',
                  color: 'black',
                }}
              >
                {name}
              </Text>
              <Text
                as="h4"
                sx={{
                  fontFamily: 'Futura Book Regular',
                  maxWidth: '300px',
                  fontSize: [null, null, null, '16px', '20px'],
                  textAlign: 'center',
                  color: 'grey',
                }}
              >
                {description}
              </Text>
            </Flex>
          </Grid>
        )
      })}
    </Grid>
  )
}
